#algolia-autocomplete-listbox-0 {
  right: 0 !important;
  left: 0 !important;
  max-width: none;
  min-width: unset;
  &::before {
    right: unset;
    left: $std-margin * 3;
  }
}
.algolia-autocomplete {
  .ds-dropdown-menu {
    margin-left: $std-margin;
  }
  .algolia-docsearch-suggestion--category-header {
    color: $body-color;
    border-bottom: 1px solid $grey-200;
  }
  .algolia-docsearch-suggestion--subcategory-column {
    color: $primary-light;
    &::before {
      background-color: $grey-200;
    }
  }
  .algolia-docsearch-suggestion--content:before {
    background-color: $grey-200;
  }
  .algolia-docsearch-suggestion--title {
    color: $body-color;
  }
  .algolia-docsearch-suggestion--text {
    color: $grey-800;
  }
  .algolia-docsearch-suggestion--highlight {
    color: $primary;
    background-color: rgba($primary, 0.1);
  }
  .ds-dropdown-menu
    .ds-suggestion.ds-cursor
    .algolia-docsearch-suggestion:not(.suggestion-layout-simple)
    .algolia-docsearch-suggestion--content {
    background-color: rgba($primary, 0.05);
  }
  .algolia-docsearch-suggestion--category-header
    .algolia-docsearch-suggestion--category-header-lvl0
    .algolia-docsearch-suggestion--highlight,
  .algolia-docsearch-suggestion--category-header
    .algolia-docsearch-suggestion--category-header-lvl1
    .algolia-docsearch-suggestion--highlight,
  .algolia-docsearch-suggestion--text .algolia-docsearch-suggestion--highlight {
    box-shadow: inset 0 -2px 0 0 rgba($primary, 0.8);
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    .algolia-docsearch-suggestion--content:before {
      display: none !important;
    }
    .algolia-docsearch-suggestion--subcategory-column {
      display: none !important;
    }
  }
}
